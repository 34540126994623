import { Button, Flex, Form, Text, LayoutGrid } from '@asktia/tia-ui'
import { FC, useEffect } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import {
    convertToApiPayload as otherImmunizationsConvertToApiPayload,
    convertToFormPayload as otherImmunizationsConvertToFormPayload,
    OtherImmunizations
} from './components/OtherImmunizations'
import {
    convertToApiFromForm as recommendedImmunizationsConvertToApiPayload,
    convertToFormPayload,
    RecommendedImmunizations,
    useRecommendedImmunizations
} from './components/RecommendedImmunizations'
import { useGetImmunizations } from './hooks/useGetImmunizations'
import { useCreateImmunizations } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/hooks/useCreateImmunizations'

export const Immunizations: FC = () => {
    const { mutate, isLoading } = useCreateImmunizations()
    const { immunizations } = useGetImmunizations()
    const recommendedImmunizations = useRecommendedImmunizations()

    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all'
        //resolver: yupResolver(GynecologySchema)
    })

    useEffect(() => {
        if (immunizations?.length > 0) {
            otherImmunizationsConvertToFormPayload(
                immunizations,
                recommendedImmunizations,
                formMethods
            )
            convertToFormPayload(
                immunizations,
                recommendedImmunizations,
                formMethods
            )
        }
    }, [immunizations, recommendedImmunizations])

    return (
        <LayoutGrid columns="one">
            <FormProvider {...formMethods}>
                <Form
                    useFormMethods={formMethods}
                    onSubmit={values => {
                        const otherImmunizations =
                            otherImmunizationsConvertToApiPayload(values)

                        const immunizations =
                            recommendedImmunizationsConvertToApiPayload(
                                values,
                                recommendedImmunizations
                            )

                        // TODO: Make a diff and remove/add immunizations
                        const body = [...otherImmunizations, ...immunizations]
                        mutate(body)
                    }}
                >
                    <RecommendedImmunizations />
                    <OtherImmunizations />

                    <Button sx={{ mt: 5, mb: 8 }} fullWidth loading={isLoading}>
                        <Flex
                            sx={{
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Text
                                sx={{
                                    color: 'white',
                                    fontSize: 4,
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    mb: 0
                                }}
                            >
                                My Vaccination info is correct
                            </Text>
                            <Text
                                sx={{
                                    color: 'white',
                                    fontSize: 0,
                                    textAlign: 'center',
                                    mb: 0
                                }}
                            >
                                Complete Medical History
                            </Text>
                        </Flex>
                    </Button>
                </Form>
            </FormProvider>
        </LayoutGrid>
    )
}
